export const menuItems = [
    {
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        label: 'menuitems.tutor.text',
        icon: 'ri-user-5-line',
        link: '/service_professionals'
    },
    {
        label: 'menuitems.parent_request.text',
        icon: 'ri-shield-star-line',
        link: '/parent_request'
    },
    {
        label: 'menuitems.assignment.text',
        icon: 'ri-book-open-line',
        link: '/assignment'
    },
    
    {
        label: 'menuitems.field_management.text',
        icon: 'ri-file-edit-line',
        subItems: [
            {
                label: 'menuitems.field_management.list.service',
                link: '/field_management/service'
            },
            {
                label: 'menuitems.field_management.list.subject',
                link: '/field_management/subject'
            },
            {
                label: 'menuitems.field_management.list.service_provider',
                link: '/field_management/service_provider'
            },
            {
                label: 'menuitems.field_management.list.student_grade',
                link: '/field_management/student_grade'
            },
            {
                label: 'menuitems.field_management.list.subject_duration',
                link: '/field_management/subject_duration'
            },
            {
                label: 'menuitems.field_management.list.subject_frequency',
                link: '/field_management/subject_frequency'
            },
            {
                label: 'menuitems.field_management.list.tutor_type',
                link: '/field_management/tutor_type'
            },
            {
                label: 'menuitems.field_management.list.student_grade_category',
                link: '/field_management/student_grade_category'
            },
        ]
    },
    {
        label: 'menuitems.setting.text',
        icon: 'ri-settings-4-line',
        subItems: [
            {
                label: 'menuitems.general.text',
                link: '/setting/general',
            },
            {
                label: 'menuitems.admin.text',
                link: '/admin',
            },
            {
                label: 'menuitems.setting.list.role',
                link: '/setting/role'
            },       
        ]
    },
]